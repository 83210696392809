<template>
  <footer class="footer dark">
    <div class="footer__container container">
      <div
        class="footer__сopyright"
        v-if="content.footer_copyright"
        v-html="content.footer_copyright"
      ></div>
      <Social
        classSocial="footer__social"
        v-if="content.social"
        :content="content.social"
      />
      <div class="footer__dev">
        <div class="footer__dev-logo">
          <DevLogo />
        </div>
        <div class="footer__dev-text">
          Разработано<br />
          в
          <a class="footer__dev-link" href="https://stebnev-studio.ru/"
            >Stebnev Studio</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Social from "@/components/block/SocialApp.vue";
import DevLogo from "@/components/element/DevLogoApp.vue";

export default {
  components: {
    Social,
    DevLogo,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.footer,
    };
  },
};
</script>

<style>
.footer {
  background-color: var(--backgraund-site-white);
}

.footer.dark {
  background-color: var(--backgraund-site-dark);
}

.footer__container {
  display: flex;
  align-items: center;
  padding: 40px 0;
}

.footer__сopyright {
  width: 300px;
  margin-right: 60px;

  font-family: var(--subtitle4-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle4-fonts-size);
  line-height: 1.5;
  color: var(--subtitle4-color-white);
}

.footer__сopyright a {
  width: 300px;
  margin-right: 60px;

  font-family: var(--subtitle4-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle4-fonts-size);
  line-height: 1.5;
  color: #ffffff;
}

.footer__сopyright a:hover {
  text-decoration: none;
}

.dark .footer__сopyright {
  color: var(--subtitle4-color-dark);
}

.footer__social {
  width: auto;
  margin-right: 55px;
}

.footer__work {
  display: flex;
  flex-direction: column;
  width: 180px;
  margin-right: 60px;
}

.footer__work-item {
  margin-bottom: 3px;

  font-family: var(--subtitle2-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle2-fonts-size);
  line-height: var(--subtitle4-line-height-subtitle);
  color: var(--subtitle2-color-white);
}

.dark .footer__work-item {
  color: var(--subtitle2-color-dark);
}

.footer__work-item:last-child {
  margin-bottom: 0;
}

.footer__day {
  margin-right: 20px;
}

.footer__mail {
  position: relative;

  max-width: 300px;

  font-family: var(--subtitle2-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle2-fonts-size);
  line-height: 1.5;
  text-decoration: none;
  color: var(--subtitle2-color-white);
}

.dark .footer__mail {
  color: var(--subtitle2-color-dark);
}

.footer__mail::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;

  width: 0;
  height: 1px;

  background-color: var(--subtitle2-color-white);
  transition: width 0.5s ease-in;
}

.dark .footer__mail::after {
  background-color: var(--subtitle2-color-dark);
}

.footer__mail:hover::after {
  width: 100%;
}

.footer__dev {
  display: flex;
  align-items: center;
  max-width: 180px;
  margin-left: auto;
}

.footer__dev-logo {
  margin-right: 18px;
}

.footer__dev-text {
  font-family: var(--subtitle4-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle4-fonts-size);
  line-height: 1.5;
  color: var(--subtitle4-color-white);
}

.dark .footer__dev-text {
  color: var(--subtitle4-color-dark);
}

.footer__dev-link {
  position: relative;

  text-decoration: none;
  color: var(--subtitle4-color-white);
}

.dark .footer__dev-link {
  color: var(--subtitle4-color-dark);
}

.footer__dev-link::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;

  width: 100%;
  height: 1px;

  background-color: var(--subtitle4-color-white);
  transition: width 0.5s ease-in;
}

.dark .footer__dev-link::after {
  background-color: var(--subtitle4-color-dark);
}

.footer__dev-link:hover::after {
  width: 0;
}

@media (max-width: 1599.98px) {
  .footer__container {
    padding: 33px 0;
  }

  .footer__сopyright {
    width: 220px;
    margin-right: 55px;

    font-size: 10px;
  }

  .footer__work {
    width: 160px;
    margin-right: 60px;
  }

  .footer__work-item {
    font-size: 14px;
  }

  .footer__mail {
    max-width: 230px;
    font-size: 14px;
  }

  .footer__dev {
    max-width: 140px;
  }

  .footer__dev-logo {
    margin-right: 10px;
  }

  .footer__dev-text {
    font-size: 10px;
  }
}

@media (max-width: 1199.98px) {
  .footer__container {
    padding: 18px 0;
  }

  .footer__сopyright {
    width: 200px;
    margin-right: 132px;
  }

  .footer__work {
    margin-right: 20px;
  }

  .footer__work-item {
    font-size: 12px;
  }

  .footer__day {
    margin-right: 30px;
  }

  .footer__mail {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .footer__container {
    padding: 10px 0;
    padding-bottom: 70px;
  }

  .footer__сopyright {
    width: 150px;
    margin-right: 40px;
  }

  .footer__work {
    width: 125px;
  }

  .footer__work-item {
    font-size: 10px;
  }

  .footer__day {
    margin-right: 18px;
  }

  .footer__dev {
    max-width: 125px;
  }

  .footer__dev-logo {
    margin-right: 12px;
  }
}

@media (max-width: 767.98px) {
  .footer__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 0;
    padding-bottom: 80px;
  }

  .footer__сopyright {
    order: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;

    font-size: 10px;
  }

  .footer__social {
    justify-content: center;
    order: 1;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .footer__work {
    order: 2;
    width: 49%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .footer__work-item {
    margin-bottom: 2px;

    font-size: 9px;
  }

  .footer__day {
    margin-right: 15px;
  }

  .footer__dev {
    order: 4;
    display: flex;
    justify-content: center;
    max-width: none;
    width: 100%;
    margin-left: 0;
  }

  .footer__dev-logo {
    display: none;
    margin-right: 12px;
  }

  .footer__dev-text {
    font-size: 9px;
  }

  .footer__dev-text br {
    display: none;
  }

  .footer__сopyright a {
    width: auto;
    margin-right: 0px;
  }
}
</style>
